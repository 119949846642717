.slider-container {
  .slider_custom_control_btn {
    @apply transform rounded-full bg-transparent p-5 text-2xl text-black transition-colors duration-500 hover:bg-red-800 hover:text-white;
  }
}
.home_banner_slider {
  .slick-dots {
    top: 80%;
  }
}

.testimonial_slider_container {
  .testimonial_slider {
    @apply relative mb-16;
    .btn_controller_container {
      @apply absolute top-24 hidden justify-end gap-x-3 lg:right-40 lg:flex xl:right-48;
    }
    .feedback_container {
      @apply rounded-lg bg-gray-50 p-5 md:absolute md:left-1/2 md:top-1/2 md:h-[300px] md:w-[500px] md:p-10 md:shadow-lg;
    }
  }
}
